<template>
  <div class="register page">
    <div class="logo_icon bordered_b flex items-center text-left flex-start">
      <div class="content">
        <div class="logo w-full py-10 ">
          <img :src="logo_url" alt="icon">
        </div>
      </div>
    </div>
    <div class="content">
      <div class="bordered_b">
        <h2 class="big-title font-bold text-left py-10 text-2xl">
          Select a subscription plan
        </h2>
      </div>

      <div class="manager-plans mb-10 mt-10 ">
        <div class="tabs flex items-center justify-center">
          <div class="tabs-wrap flex ">
            <!-- <button type="button" @click="is_route_manager = true" class="font-bold"
              :class="{'active underline bold-green': is_route_manager,
              ' text-grey-darker': !is_route_manager}"
            >Route manager</button>
            <span class="ml-2 mr-2">|</span> -->
            <button type="button" @click="is_route_manager = false" class="font-bold text-primary"
              :class="{'active underline bold-green': !is_route_manager,
              ' text-grey-darker': is_route_manager}"
            >Fleet manager</button>
          </div>

        </div>
          <div class="flex justify-center mt-5"><div class="manger-info text-center mt-1 w-1/2"><p class="font-semibold whitespace-pre-line">If you have 1 or more vehicles, our Fleet plans are what you need.</p>
            <p class="font-semibold whitespace-pre-line">Create compliant routes, register your vehicles and create driver logins</p>
          </div>
        </div>
        <div class="subscription_plans grid grid-cols-4 gap-15 m-auto">
          <div class="plan flex flex-col items-center justify-between px-8 py-6"
               v-for="(plan, index) in is_route_manager ? annual_subscription_plans_route : annual_subscription_plans_fleet" :key="index">
            <div class="inner flex flex-col items-center justify-between height_av">
              <div class="flex flex-col justify-between">
                <strong class="plan_name block font-semibold text-grey-darkest text-base capitalize mb-2">{{ plan.name }}</strong>
                <h5 class="text-3xl flex items-center justify-center leading-tight mb-3">
                  <span class="text-grey-darker font-normal text-base">£</span>
                  <strong class="inline-block text-grey-darkest font-medium mx-1">{{ plan.price/100 }}</strong>
                  <span class="text-grey-darker font-normal text-base"> / {{ plan.billing_period }}</span>
                </h5>
              </div>
              <div class="flex flex-col justify-between">
                <p class="text-grey-darker text-center mb-6">{{plan.description}}</p>
                <button
                    type="button"
                    class="green-white w-full font-medium"
                >
                  <router-link
                      :to="{ name: 'RegisterForm', params: {plan: plan}}">Select Plan
                  </router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      logo_url: null,
      annual_subscription_plans_fleet: null,
      annual_subscription_plans_route: null,
      is_route_manager: false
    }
  },
  methods: {
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).icon_url || null
    },
    async getSubscriptionPlans() {
      try {
        const response = await this.$http.get(`${this.$http.apiUrl()}/subscription-plans`) || null;
        this.annual_subscription_plans_fleet = response?.data?.subscriptionPlansFleet;
        this.annual_subscription_plans_route = response?.data?.subscriptionPlansRoute;
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.logoUrl();
    await this.getSubscriptionPlans();
  }
}
</script>

<style lang="scss" scoped>
.tabs-wrap {
  font-size: 14px;
  .manager-plans {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.subscription_plans {
  margin-top: 30px;
  .plan {
    background-color: rgb(243, 248, 254);
    border-radius: 4px;
  }
}
.tabs {
  position: relative;
}
</style>
